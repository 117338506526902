import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation } from '@tanstack/react-query'

export const useGenerateSubtitlesMutation = () => {
  return useMutation({
    mutationKey: ['user', 'update'],
    mutationFn: async (fileToken: string) => {
      await makeApiRequest({
        url: `${process.env.NEXT_PUBLIC_UPLOAD_URL}:9092/api/trnscb`,
        method: 'POST',
        isAbsoluteUrl: true,
        data: {
          fileToken,
        },
        isAuthenticated: true,
        fallbackError: 'Failed to generate subtitles',
      })
    },
  })
}
