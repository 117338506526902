import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Image,
    ModalFooter,
    Button,
    Link
} from '@chakra-ui/react'
interface errorData {
    header: string,
    description: string,
    icon: string
    btn?: string
}
export default function UploadErrorModal({
    isOpen,
    handleClose,
    data
}: {
    isOpen: boolean
    handleClose: (close: boolean) => void
    data: errorData
}) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => handleClose(true)}
            isCentered
            closeOnOverlayClick={false}
        >
            <ModalOverlay overflowX={'hidden'} />
            <ModalContent
                overflowX={'hidden'}
                rounded="xl"
                maxW={['95%', '95%', '550px']}
            >
                <ModalHeader
                    color={"#3E3E3E"}
                    display="flex"
                    alignItems="center"
                    gap={2}
                >
                    {data.header}
                </ModalHeader>
                <ModalCloseButton mt={2} />
                <hr />
                <ModalBody>
                    <Box textAlign={'center'}>

                        <img
                            src={data.icon}
                            alt={data.header}
                            width={'22%'}
                            style={{ margin: '1.5rem auto' }}
                        />
                        <Text
                            fontSize="base"
                            fontWeight="500"
                            wordBreak="break-word"
                            aria-label={data.description}

                        >
                            {data.description}
                        </Text>
                        {
                            data.description.search("10 GB") !== -1 ?
                                <Text
                                    fontSize="14px"
                                    fontWeight="400"
                                    wordBreak="break-word"
                                    aria-label={data.description}
                                    color={"#9f9f9f"}
                                    mt={2}

                                >
                                    Want to get verified? Learn more <Link color={'lyk-green'} href="/faqs?query=What does 'Upload Verified' mean" target='_blank'>here.</Link>
                                </Text>
                                : ""
                        }
                    </Box>
                </ModalBody>
                <ModalFooter textAlign={'center'}>
                    <Button
                        paddingX={20}
                        bg="lyk-green"
                        color="white"
                        _hover={{ opacity: 0.7 }}
                        width={'100%'}
                        fontWeight={500}
                        onClick={() => handleClose(false)}
                    >
                        {data?.btn ? data?.btn : 'Retry Upload'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
