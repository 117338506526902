import {
  forwardRef,
  ButtonGroup as CBG,
  ButtonGroupProps,
} from '@chakra-ui/react'
import React from 'react'

export const ButtonGroup = forwardRef<ButtonGroupProps, 'div'>((props, ref) => {
  const { isAttached, spacing = 2 } = props
  const styles = {
    flexDirection: 'row',
    '& > *:not(style) ~ *:not(style)': {
      marginStart: spacing,
      marginTop: 0,
    },
  }
  if (!isAttached) {
    return <CBG ref={ref} sx={styles} {...props} />
  }
  return <CBG ref={ref} {...props} />
})

const inactiveStyles = {
  color: 'black',
  bg: '#ECECEC',
  _hover: { bg: 'lyk-green', color: 'white' },
}

/**
 * @example (
 * <SelectButtonGroup onChange={handleChangeValue} value={value}>
 *  <Button value={value1}>label1</Button>
 *  <Button value={value2}>label2</Button>
 * </SelectButtonGroup>
 * )
 * @note
 * must have multiple child elements
 */
export const SelectButtonGroup: React.FC<{
  onChange: (value: string) => void
  value: string
  groupProps?: ButtonGroupProps
  children: React.ReactNode
}> = ({ onChange, value, children, groupProps }) => {
  if (!children) throw new Error('Children required')
  // iterate over array of child nodes to apply extended props
  return (
    <ButtonGroup {...groupProps} w={'100%'} display={'flex'} flexWrap={'wrap'} gap={[1, 1, 3]}>
      {React.Children.map(children as React.ReactElement[], (CHILD) => {
        return React.cloneElement(CHILD, {
          onClick: () => {
            if (value === CHILD?.props?.value) return
            onChange(CHILD?.props?.value)
          },
          ...(value !== CHILD?.props?.value && { sx: inactiveStyles }),
        })
      })}
    </ButtonGroup>
  )
}
