import { Text, Image, Box, Progress, Flex } from '@chakra-ui/react'

export const ProgressBar = ({
  status,
  progress,
}: {
  status: 'uploading' | 'transcoding'
  progress: number
}) => {
  return (
    <>
      {status == 'uploading' ? (
        <Box zIndex={-1} w="100%">
          <Progress
            height={'5px'}
            className="progressbar"
            colorScheme={'red'}
            rounded={'full'}
            value={progress}
            w={'100%'}
            mb={1}
          />
          <Flex
            align={['flex-start', 'flex-start', 'center']}
            flexDir={['column', 'column', 'row']}
            gap={[0, 0, 3]}
          >
            <Text opacity={0.7} fontSize={'14px'} fontWeight={500}>
              Uploading {progress}%
            </Text>
            {/* <Text
              display={['none', 'none', 'block']}
              opacity={0.7}
              fontSize={'14px'}
              fontWeight={500}
            >
              &middot;
            </Text>
            <Text opacity={0.7} fontSize={'14px'} fontWeight={500}>
              10 min remaining
            </Text> */}
          </Flex>
        </Box>
      ) : (
        <Flex align={'center'} gap={3}>
          <Flex gap={3} align={'center'}>
            <Image alt="Share" src="/upload/share.svg" boxSize={6} />
            <Image alt="Screen" src="/upload/screen.svg" boxSize={6} />
            {progress >= 100 ? (
              <Image alt="Check" src="/upload/check-green.svg" boxSize={6} />
            ) : (
              <Image alt="Check" src="/upload/check-round.svg" boxSize={6} />
            )}
          </Flex>
          <Flex align={'center'} gap={3}>
            <Text opacity={0.7} fontSize={'14px'} fontWeight={500}>
              {progress < 100 ? `Transcoding in progress` : 'Ready to Publish'}
            </Text>
            {/* {progress < 100 ? (
              <>
                <Text opacity={0.7} fontSize={'14px'} fontWeight={500}>
                  &middot;
                </Text>
                <Text opacity={0.7} fontSize={'14px'} fontWeight={500}>
                  10 min remaining
                </Text>
              </>
            ) : null} */}
          </Flex>
        </Flex>
      )}
    </>
  )
}
