// https://uploader.lykstage.com:9092/api/txStatus

import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'
import { useUser } from '@/src/store/user-store'
import { useToken } from '@/src/store/token-store'

interface TxCodeStatus {
  lessTo30: 0 | 1
  transcodeCompleted: 0 | 1
  transferToS3Completed: 0 | 1
  transcodingFailed: 0 | 1
  thumbnailGenerated: 0 | 1
  videoLength: 0 | 1
  s3FileNotFound: 0 | 1
}

interface CreateVodResp {
  id: string
  playBackUrl: string
  prevThumbnailImgUrl: string
  prevThumbnailImgUrl_1: string
  prevThumbnailImgUrl_105: string
  prevThumbnailImgUrl_1_105: string
  prevThumbnailImgUrl_1_210: string
  prevThumbnailImgUrl_1_420: string
  prevThumbnailImgUrl_2: string
  prevThumbnailImgUrl_210: string
  prevThumbnailImgUrl_2_105: string
  prevThumbnailImgUrl_2_210: string
  prevThumbnailImgUrl_2_420: string
  prevThumbnailImgUrl_420: string
  previewUrl: string
  thumbnailImgUrl: string
  thumbnailImgUrl_1: string
  thumbnailImgUrl_105: string
  thumbnailImgUrl_1_105: string
  thumbnailImgUrl_1_210: string
  thumbnailImgUrl_1_420: string
  thumbnailImgUrl_2: string
  thumbnailImgUrl_210: string
  thumbnailImgUrl_2_105: string
  thumbnailImgUrl_2_210: string
  thumbnailImgUrl_2_420: string
  thumbnailImgUrl_420: string
}

interface ResponseData {
  failed: 0 | 1
  uploaded: 0 | 1
  drafted: 0 | 1 | 2
  transcoded: 0 | 1 | 2
  sourceType: 0 | 1 | 2
  createVod: 0 | 1 | 2
  duration: number
  size: number
  txCodeStatus: TxCodeStatus
  createVodResp: CreateVodResp
  vodId: string
  msg: string
  durationFail?: boolean
}

interface ApiResponse {
  respCode: number
  message: string
  response: ResponseData
}

export interface Category {
  categoryId: string
  categoryName: string
}
export const useTranscodingStatusQuery = ({
  isUploadComplete,
  isTranscodingComplete,
  fileToken,
}: {
  isUploadComplete: boolean
  fileToken: string
  isTranscodingComplete: boolean
}) => {
  const { accessToken } = useToken()
  const { user } = useUser()

  return useQuery({
    queryKey: ['transcoding', fileToken],
    queryFn: async () => {
      // const url = 'https://uploader.lykstage.com:9092/api/txStatus'
      // const payload = {
      //   fileToken: 'file_1713351284727.mp4',
      // }

      // const data = await axios.post(url, payload)
      const response = await makeApiRequest({
        method: 'POST',
        url: `${process.env.NEXT_PUBLIC_UPLOAD_URL}:9092/api/txStatus`,
        isAuthenticated: true,
        fallbackError: 'Error while fetching transcoding status.',
        data: { fileToken: fileToken },
      })

      const data: ResponseData = response.response
      return data as ResponseData
    },

    // enabled: true,
    refetchOnWindowFocus: true,
    enabled: !!(
      user &&
      accessToken &&
      isUploadComplete &&
      !isTranscodingComplete &&
      fileToken
    ),

    refetchInterval: 2000,
  })
}
