import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { memo, useEffect, useState } from 'react'
import {
  Control,
  FieldValues,
  RegisterOptions,
  useController,
} from 'react-hook-form'

const labelStyles = {
  fontSize: {
    base: 'sm',
    md: 'md',
  },
  mt: {
    base: '1',
    md: '1',
  },
  fontWeight: '500',
  marginBottom: '5px',
  color: '#525252',
}

const ControlledSelect = memo(
  ({
    control,
    name,
    id,
    label,
    rules,
    isClearable,
    isMulti,
    placeholder,
    options,
    isRequired = false,
    isDisabled = false,
    ...props
  }: {
    control: Control<FieldValues> | undefined
    name: string
    id: string
    label: string
    placeholder?: string
    isRequired?: boolean
    isDisabled?: boolean
    rules:
    | Omit<
      RegisterOptions<FieldValues, string>,
      'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
    >
    | undefined
    isClearable?: boolean
    isMulti?: boolean
    options: { label: string; value: string }[]
    portalTarget?: HTMLElement
  }) => {
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    } = useController({
      name,
      control,
      rules,
    })

    const [menuPortalTarget, setMenuPortalTarget] =
      useState<HTMLElement | null>(null)

    useEffect(() => {
      setMenuPortalTarget(document.body)
    }, [setMenuPortalTarget])

    return (
      <FormControl isInvalid={!!error} id={id} isRequired={isRequired}>
        <FormLabel {...labelStyles} htmlFor={name}>
          {label}
        </FormLabel>
        <Select
          // menuPosition={'fixed'}
          // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // menuPortalTarget={menuPortalTarget}
          menuPlacement={(id === "category" || id === "language") ? "top" : "auto"}
          menuShouldScrollIntoView={true}
          size={'md'}
          isMulti={isMulti}
          useBasicStyles
          isClearable={isClearable}
          name={name}
          instanceId={id}
          ref={ref}
          chakraStyles={{
            control: (provided) => ({
              ...provided,
              fontSize: {
                base: 'sm',
                md: 'md',
              },
              borderColor: '#e3e3e3',
            }),

            placeholder: (provided) => ({
              ...provided,
              fontSize: {
                base: 'sm',
                md: 'md',
              },
            }),
          }}
          selectedOptionStyle={'check'}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          maxMenuHeight={200}
          minMenuHeight={200}
          options={options}
          isRequired={isRequired}
          isDisabled={isDisabled}
          // {...selectProps}
          {...props}
        />

        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    )
  }
)

ControlledSelect.displayName = 'ControlledSelect'

export default ControlledSelect
