import { Box } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { memo } from 'react'
const Player = dynamic(() => import('../Player'), { ssr: false })

const PreviewPlayerWrapper = memo(
  ({
    playbackURL = '',
    thumbnailImage = '',
  }: {
    playbackURL: string
    thumbnailImage?: string
  }) => {
    const videoOptions = {
      autoplay: false,
      playsinline: true,
      controls: true,
      preload: 'auto',
      poster: thumbnailImage || '',
      sources: [
        {
          src: playbackURL,
          type: 'application/x-mpegURL',
        },
      ],
    }

    if (videoOptions.sources[0].src === '')
      return <Box aspectRatio={16 / 9} bg="black" w="100%" />

    return (
      <Box aspectRatio={16 / 9} bg="black">
        <Player {...videoOptions} />
      </Box>
    )
  }
)

PreviewPlayerWrapper.displayName = 'PreviewPlayerWrapper'

export default PreviewPlayerWrapper
