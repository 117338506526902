import {
  Text,
  Flex,
  Button,
  Image,
  Box,
  Link,
} from '@chakra-ui/react'
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import React from 'react'
import { VideoCard } from '../ui/video-card'
import { Icons } from '../icons'
import { toast } from 'sonner'

export default function VideoPublished({
  title,
  link,
  thumbnail,
  duration,
}: {
  title: string
  link: string
  thumbnail: string
  duration: number
}) {
  const copyToClipboardLink = () => {
    navigator.clipboard.writeText(link)
    toast.success('Copied to clipboard')
  }
  const copyToClipboardEmbed = () => {
    navigator.clipboard.writeText(
      `<iframe width="560" height="315" src="${link}" title="LYKSTAGE video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    )
    toast.success('Copied to clipboard')
  }
  return (
    <Box>
      <Link href={link} target="_blank">
        <VideoCard
          thumbnailUrl={thumbnail}
          omitDetails={true}
          videoDuration={`${duration}`}
          themeColor={'#E79051'}
          creatorName={''}
          title={title}
          watchTime={''}
        />
      </Link>
      <Flex
        mt={5}
        bg={'gray.100'}
        p={3}
        alignItems={'center'}
        rounded={'md'}
        overflow={'hidden'}
      >
        <Box flex={1} overflow="hidden">
          <Text fontWeight={500} fontSize={15}>
            Video link
          </Text>
          <Flex columnGap={2}>
            <Link
              fontWeight={500}
              fontSize={15}
              color={'#318FD2'}
              href={link}
              target="_blank"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              display="block"
            >
              {link}
            </Link>
            <Box className="cursor-pointer" onClick={copyToClipboardLink}>
              <Image boxSize={6} src={'/copy.svg'} alt="Copy" />
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Flex
        mt={3}
        bg={'gray.100'}
        p={3}
        justifyContent={'flex-start'}
        alignItems={'center'}
        rounded={'md'}
        columnGap={4}
      >
        <Text fontSize={15} fontWeight={500} whiteSpace="nowrap" hideBelow="sm">
          Share a link
        </Text>
        <Flex
          flexWrap="wrap"
          columnGap={4}
          rowGap={2}
          flex={1}
          justifyContent={['space-around', 'space-around', 'flex-start']}
        >
          <Button
            className="cursor-pointer border-none bg-transparent"
            p={'0'}
            size={'xs'}
            onClick={copyToClipboardEmbed}
          >
            <Icons.code className="h-[20px] w-[20px] text-black/70" />
          </Button>
          <FacebookShareButton url={link}>
            <Image
              className="cursor-pointer"
              boxSize={5}
              src={'/socials/facebook.svg'}
              alt="Copy"
            />
          </FacebookShareButton>
          <WhatsappShareButton url={link}>
            <Image
              className="cursor-pointer"
              boxSize={5}
              src={'/socials/whatsapp.svg'}
              alt="Copy"
            />
          </WhatsappShareButton>
          <TwitterShareButton url={link}>
            <Image boxSize={5} src={'/socials/twitter.svg'} alt="Copy" />
          </TwitterShareButton>
          <EmailShareButton url={link}>
            <Image boxSize={5} src={'/socials/email.svg'} alt="Copy" />
          </EmailShareButton>
          <LinkedinShareButton url={link}>
            <Image boxSize={5} src={'/socials/linkedin.svg'} alt="Copy" />
          </LinkedinShareButton>
        </Flex>
      </Flex>
    </Box>
  )
}
