import { makeApiRequest } from '@/src/lib/api-request'
import {request, requestV3} from '@/src/lib/customAxios'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'

interface VideoDetails {
  fileToken: string
  title: string
  description: string
  thumbnailUrl: File | string
  categoryIds: string
  languageIds: string
  uploadThumbnail: boolean
  visibility: '0' | '1' | '2'
  schedule_status: '0' | '1'
  schedule_date: string
  schedule_time: string
  subtitlesRequested: '0' | '1'
  isMadeForKids?: string
  isRestrictAge?: string
}

export const useSaveVideoDetailsMutation = ({
  draftMode,
}: {
  draftMode: boolean
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['video', 'save'],
    mutationFn: async (
      data: VideoDetails,
      customCallback?: (customImgURL: string) => void
    ) => {
      if (data.uploadThumbnail) {
        console.log('uploading image')
        const formData = new FormData()
        formData.append('file', data.thumbnailUrl)
        try {
          console.log('try block')
          const response = await requestV3({
            url: '/ImageUpload/uploadThumbnail',
            method: 'POST',
            data: formData,
          })
          if (!response.data.fullUrl) {
            throw new Error('Failed to upload image')
          }
          console.log(response)
          data.thumbnailUrl = response.data.fullUrl
          customCallback && customCallback(response.data.fullUrl)
        } catch (e) {
          throw new Error('Failed to upload image')
        }
      }

      await makeApiRequest({
        url: '/Video_Upload/saveVideoDetailsById',
        method: 'POST',
        data: data,
        isAuthenticated: true,
        fallbackError: 'Failed to save video details',
        isV3Api: true
      })
      return data
    },
    onSuccess: () => {
      if (draftMode) {
        queryClient.invalidateQueries({ queryKey: ['draft-videos'] })
        queryClient.invalidateQueries({ queryKey: ['scheduled-videos'] })
        queryClient.invalidateQueries({ queryKey: ['all-video-count'] })
      }
    },
  })
}
