import {
  Text,
  Flex,
  Button,
  Image,
  ModalBody,
  Box,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'sonner'
import UppyUploader from './uppyUploader';
import { Icons } from '../icons';
import { getDeviceType, isPWA } from '@/src/lib/utils';
import { useUser } from '@/src/store/user-store';
import { useChannelDetailsQuery } from '@/src/queries/my-channel/channel-details';

const fileTypes = [
  'mp4',
  'mkv',
  'avi',
  'mov',
  'flv',
  '3gp',
  'webm',
  'ogg',
  'mpg',
]


export const UploadUI = ({
  handleFileChange,
  handleFileChangeCloud,
  resetUpload,
  onClose,
  setErrorData,
   onErrorOpen
}: {
  handleFileChange: (file: File | null, uppyUploadUrl: string) => void,
   handleFileChangeCloud: (file: File | null, uppyUploadUrl: string) => void,
    resetUpload: Function,
     onClose: Function,
     setErrorData: Function,
      onErrorOpen: Function
}) => {
  const { user } = useUser()
  const { data: channelData } = useChannelDetailsQuery(
    user?.channelShareName || ''
  )
  return (
    <ModalBody
      mt={2}
      padding={0}
      maxWidth={'100vw'}
      overflowX={'hidden'}
    >
      <Tabs align='center' colorScheme='teal' maxWidth={'100vw'}>
        <TabList mb='1em'>
          <Tab><Icons.upload className='w-4 h-4 mr-2' />Upload from device</Tab>
          {isPWA() && getDeviceType() === "iPhone" ? "" : <Tab><Icons.uploadCloud className='w-4 h-4 mr-2' />Upload from cloud</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              // multiple
              types={fileTypes}
              classes="dragdrop"
              onTypeError={() => {
                toast.error('Please upload a valid video file!', {
                  duration: 1500,
                })
              }}
            >
              <>
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDir={'column'}
                >
                  <Image
                    mb={5}
                    mt={2}
                    boxSize="80px"
                    src={'/upload/cloud.svg'}
                    alt="Upload"
                  />

                  <Text
                    align={'center'}
                    opacity={0.7}
                    fontWeight={500}
                    fontSize={24}
                    display={['none', 'block']}
                  >
                    Drag and drop video files to upload
                  </Text>
                  <Text
                    align={'center'}
                    mb={5}
                    opacity={0.7}
                    fontWeight={500}
                    fontSize={16}
                  >{
                      Number(channelData?.uploadVerify) || Number(channelData?.isVerifiedTick) ?
                        <>You are eligible to upload videos up to <b>12 hours long</b>  and <b>30 GB in size.</b></>
                        :
                        <>You are eligible to upload videos up to <b>30 minutes long</b>  and <b>10 GB in size.</b></>
                    }
                  </Text>
                  <Button
                    mb={5}
                    _hover={{ bg: 'lyk-green', color: 'white' }}
                    color={'white'}
                    bg={'lyk-green'}
                    rounded={100}
                    value={'LYK Coins'}
                    fontSize={14}
                    fontWeight={500}
                    width={125}
                  >
                    Select files
                  </Button>
                </Flex>
              </>

              <>
                <Text mb={5} fontWeight={400} fontSize={14} align={'center'}>
                  You can upload your videos in any of the following formats:
                  <br />
                  mp4, mov, flv, 3gp, avi, mpg, ogg, webm, mkv and H.264
                </Text>

                <Text
                  color={'lyk-green'}
                  fontSize={16}
                  fontWeight={600}
                  align={'center'}
                  mb={5}
                >
                  Videos uploaded must be longer than 3 minutes to start earning
                  from watch time.
                </Text>
              </>
            </FileUploader>
            <Text mb={7} fontSize={14} fontWeight={400} align={'center'}>
              By submitting your content to LYKSTAGE, you agree to our{' '}
              <Link
                color={'lyk-green'}
                fontWeight={600}
                target="_blank"
                href="/termsofservice"
                onClick={(e) => e.stopPropagation()}
              >
                Terms of Service
              </Link>{' '}
              ,{' '}
              <Link
                color={'lyk-green'}
                fontWeight={600}
                target="_blank"
                href="/privacypolicy"
                onClick={(e) => e.stopPropagation()}
              >
                Privacy Policy
              </Link>{' '}
              , and{' '}
              <Link
                color={'lyk-green'}
                fontWeight={600}
                target="_blank"
                href="/community"
                onClick={(e) => e.stopPropagation()}
              >
                Community Guidelines
              </Link>
              . You also confirm that your content does not infringe on any
              copyright or privacy rights.
            </Text>
          </TabPanel>
          <TabPanel>

            <UppyUploader handleFileChange={handleFileChangeCloud} resetUpload={resetUpload} onClose={onClose} setErrorData={setErrorData} onErrorOpen={onErrorOpen}/>

          </TabPanel>
        </TabPanels>

      </Tabs>
    </ModalBody>
  )
}
