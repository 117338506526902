import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation } from '@tanstack/react-query'
import { usePathname } from 'next/navigation'
import { useQueryClient } from '@tanstack/react-query'

interface VideoDetails {
  fileToken: string
}

interface ResponseVideoDetails {
  eventId: string
  UniqueWatchTime: string
  userId: string
  priceType: string
  duration: string
  type: string
  eventTitle: string
  eventContent: string
  watchCount: string
  commentCount: string
  likeCount: string
  dislikeCount: string
  eventEntryDate: string
  subscriberCount: string
  channelName: string
  channelShareName: string
  firstName: string
  imageUrl: string
  channelData: string
  cdnVODId: string
  cdnTranscode: string
  hlsLink: string
  categoryId: string
  themeColor: string
  encryptVideoUrl: string
  isWatch: string
  isLike: string
  isDisLike: string
  thumbnailImage: string
  isSubscribe: string
}

export const usePublishVideoMutation = ({
  draftMode,
}: {
  draftMode: boolean
}) => {
  const queryClient = useQueryClient()
  const pathname = usePathname()
  return useMutation({
    mutationKey: ['video', 'save'],
    mutationFn: async (data: VideoDetails) => {
      
      const response = await makeApiRequest({
        url: '/Video_Upload/publishVideo',
        method: 'POST',
        data: data,
        isAuthenticated: true,
        fallbackError: 'Failed to save publilsh video',
        isV3Api: true
      })
      return response.response[0] as ResponseVideoDetails
      // console.log(response)
    },
    onSuccess: () => {
      if (!draftMode) {
        queryClient.invalidateQueries({ queryKey: ['uploaded-videos'] })
        queryClient.invalidateQueries({ queryKey: ['draft-videos'] })
        queryClient.invalidateQueries({ queryKey: ['scheduled-videos'] })
        queryClient.invalidateQueries({ queryKey: ['all-video-count'] })
      }
    },
  })
}
