import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image
} from '@chakra-ui/react'
import VideoPublished from './videopublished'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import { useRouter } from 'next/navigation'
import { useQueryClient } from '@tanstack/react-query'
import { useUser } from '@/src/store/user-store'
import { format } from 'date-fns'
import { Icons } from '../icons'
import { toast } from 'sonner'

export default function PublishedVideoModal({
  isOpen,
  onClose,
  title,
  link,
  thumbnail,
  duration,
  draftMode,
  scheduled,
  cdnTranscode
}: {
  isOpen: boolean
  onClose: () => void
  title?: string
  link?: string
  thumbnail?: string
  duration?: number
  draftMode?: boolean
  scheduled?: string
  cdnTranscode?: string
}) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { user } = useUser()
  function handleClose() {

    onClose()
    router.push(`/c/${user?.channelShareName}?tab=${scheduled ? 'scheduled' : cdnTranscode === '0' ? 'inProgress' : 'uploads'}`)
    if (draftMode || scheduled || cdnTranscode === '0') {
      queryClient.invalidateQueries({ queryKey: ['uploaded-videos'] })
      queryClient.invalidateQueries({ queryKey: ['draft-videos'] })
      queryClient.invalidateQueries({ queryKey: ['scheduled-videos'] })
      queryClient.invalidateQueries({ queryKey: ['all-video-count'] })
      queryClient.invalidateQueries({ queryKey: ['in-progress-videos'] })
    }

  }
  const copyToClipboardLink = () => {
    navigator.clipboard.writeText(link || "")
    toast.success('Copied to clipboard')
  }
  return (
    <Modal
      autoFocus={false}
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay overflowX={'hidden'} />
      <ModalContent
        overflowX={'hidden'}
        rounded="xl"
        maxW={['95%', '95%', '550px']}
      >
        <ModalHeader
          color={scheduled || cdnTranscode === '0' ? "#3E3E3E" : "lyk-green"}
          display="flex"
          alignItems="center"
          gap={2}
        >
          {scheduled ? `Video Scheduled for ${format(new Date(scheduled), "do MMM, hh:mm a")}`
            : cdnTranscode === '0' ? <>You’re all set <IoCheckmarkCircleOutline size={26} color="#3E3E3E" /></>
              : <>Video Published <IoCheckmarkCircleOutline size={26} color="#24BFA3" /></>}

        </ModalHeader>
        <ModalCloseButton mt={2} />
        <hr />
        <ModalBody>
          {scheduled ? <Text display={'flex'} >
            Your video will appear under the scheduled tab on<Text color={'lyk-green'} cursor={'pointer'} onClick={handleClose}>&nbsp;My Channel</Text>
          </Text> : ""}
          <Box px={[0]} py={4}>
            {cdnTranscode === '0' ?
              <Box>
                <Box
                  width={'100%'}
                  aspectRatio={16 / 9}
                  border={'solid'}
                  borderColor={'#eaeaea'}
                  borderWidth={3}
                  bg={'#eaeaea'}
                  mb={5}
                  rounded={'md'}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDir="column"
                >
                  <Image
                    src="/upload/generating-preview.svg"
                    alt="Generating preview"
                    width={'22%'}
                  />
                  </Box>
                <Text color={'lyk-green'} fontSize={"20px"}>Your video will be published on your channel when transcoding is complete!</Text>
                <Text
              fontSize="base"
              fontWeight="500"
              noOfLines={2}
              wordBreak="break-word"
              aria-label={title}
              mt={3}
            >
              {title}
            </Text>
                <Flex
                  mt={2}
                  bg={'gray.100'}
                  p={3}
                  alignItems={'center'}
                  rounded={'md'}
                  overflow={'hidden'}
                >
                  <Box flex={1} overflow="hidden">
                    <Text fontWeight={500} fontSize={15}>
                      Video link
                    </Text>
                    <Flex columnGap={2}>
                      <Link
                        fontWeight={500}
                        fontSize={15}
                        color={'#318FD2'}
                        href={link}
                        target="_blank"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        display="block"
                      >
                        {link}
                      </Link>
                      <Box className="cursor-pointer" onClick={copyToClipboardLink}>
                        <Image boxSize={6} src={'/copy.svg'} alt="Copy" />
                      </Box>
                    </Flex>
                  </Box>
                </Flex>

              </Box>
              : <VideoPublished
                title={title || ''}
                link={link || ''}
                thumbnail={thumbnail || ''}
                duration={duration || 0}
              />}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
