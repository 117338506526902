import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'
import { useUser } from '@/src/store/user-store'
import { useToken } from '@/src/store/token-store'

export interface ChannelDetails {
  firstName: string
  imageUrl: string
  channelUrl: string
  subscriberCount: string
  videoCount: string
  registrationDateTime: string
  bio: string
  countryName: string
  coverUrl: string
  channelName: string
  channelShareName: string
  isVerifiedTick: string
  channelId: string
  waterMarkImage: string
  instagramLink: string
  facebookLink: string
  emailLink: string
  twitterLink: string
  websiteLink: string
  themeColor: string
  totalWatchHours: string
  subscribeStatus: string
  uploadVerify: string
}

export const useChannelDetailsQuery = (channelShareName: string) => {
  const { user, _hasHydrated } = useUser()
  const { accessToken } = useToken()
  return useQuery({
    queryKey: ['channel-details', channelShareName, user],
    queryFn: async () => {
      if (user) {
        const response = await makeApiRequest({
          method: 'POST',
          url: '/Channel/getChannelDetails_Withlogin',
          isAuthenticated: true,
          data: { channelShareName },
          fallbackError: 'Error while fetching user details',
          isV3Api: true
        })
        const channelDetails: ChannelDetails = response.response.data[0]
        if (!channelDetails) throw new Error('Channel not found')
        return channelDetails
      } else {
        const response = await makeApiRequest({
          method: 'POST',
          url: '/Channel/getChannelDetails_Withoutlogin',
          isAuthenticated: false,
          data: { channelShareName },
          fallbackError: 'Error while fetching user details',
          isV3Api: true
        })
        const channelDetails: ChannelDetails = response.response.data[0]
        if (!channelDetails) throw new Error('Channel not found')
        return channelDetails
      }
    },
    enabled: !!channelShareName && !!_hasHydrated && (!user || !!accessToken),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 1,
  })
}
