import { useState } from 'react'
import VideoUpload from './videoupload'
import { useDisclosure } from '@chakra-ui/react'
import PublishedVideoModal from './publishedmodal'

export interface FinalDataInterface {
  title: string
  thumbnail: string
  duration: number
  url: string
  scheduled: string
  cdnTranscode: string
}

export default function VideoUploadFlow({
  onOpen,
  onClose,
  isOpen,
}: {
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
}) {
  const {
    isOpen: isPublishedOpen,
    onOpen: onPublishedOpen,
    onClose: onPublishedClose,
  } = useDisclosure()

  const [finalData, setFinalData] = useState<FinalDataInterface | null>(null)
  // useEffect(() => {
  //   if (finalData) {
  //     onClose()
  //     onPublishedOpen()
  //   }
  // }, [finalData, onClose, onPublishedOpen])

  return (
    <>
      <VideoUpload
        isOpen={isOpen}
        onClose={onClose}
        setFinalData={setFinalData}
        openPublishedModal={onPublishedOpen}
      />
      <PublishedVideoModal
        isOpen={isPublishedOpen}
        onClose={onPublishedClose}
        title={finalData?.title}
        link={finalData?.url}
        thumbnail={finalData?.thumbnail}
        duration={finalData?.duration}
        scheduled={finalData?.scheduled}
        cdnTranscode={finalData?.cdnTranscode}
      />
    </>
  )
}
